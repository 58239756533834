import React, { useEffect, useState, useContext, useRef } from "react";
import {
  studentRegister,
  // sendVerification,
  sendPassportVerification,
  checkPhoneNumberInDb,
  verifyEmail,
} from "../../api/student";
import { programOptions } from "../../utils";
import BBAComponent from "./BBAComponent";
import MBAComponent from "./MBAComponent";
import BTSComponent from "./BTSComponent";
import { toast, Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";
import { Spin } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { HiArrowCircleRight, HiArrowCircleLeft } from "react-icons/hi";
import * as EmailValidator from "email-validator";
import {
  validateForm,
  validateFiles,
  validatePhoneNumber,
  firstPanelValidation,
  secondPanelValidation,
} from "../../helper/validateForm";
import { AllCountryPhoneNumberDetails } from "../../helper/PhoneNumberLength";

import { decryptRole } from "../Authentication/EncryptionUtils";
import {
  FormSubmissionEmail,
  FormSubmissionEmailAdmin,
  FormSubmissionEmailBDM,
} from "../../helper/FormubmissionForm";

const Component1 = ({
  formState,
  handleInputChange,
  setFormState,
  countryCodes,
  setPhoneNumber,
  phoneNumber,
  isOtpVerified,
  isEmailVerified,
  isMobileVerified,
  verifyValidEmail,
  verifyValidPassport,
  verifyOtp,
  OTP,
  verifingEmail,
  verifingOTP,
  setOTP,
  checkPhoneNumberDB,
  role, // changes done for role === 'admin'
}) => {
  return (
    <div className="overflow-hidden p-8 md:p-10 ">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-2">
        {/* <Grid item xs={12}> */}
        <FormControl fullWidth>
          <InputLabel id="title-label">Title</InputLabel>
          <Select
            labelId="title-label"
            id="title"
            name="title"
            value={formState.title}
            label="Title"
            onChange={handleInputChange}
            required
          >
            <MenuItem value="Mr.">Mr.</MenuItem>
            <MenuItem value="Mrs.">Mrs.</MenuItem>
            <MenuItem value="Ms.">Ms.</MenuItem>
          </Select>
        </FormControl>
        {/* </Grid> */}

        {/* <Grid item xs={12} sm={6}> */}
        <TextField
          required
          id="lastName"
          name="lastName"
          label="Last name"
          fullWidth
          autoComplete="family-name"
          value={formState.lastName.toUpperCase()}
          onChange={handleInputChange}
        />
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={6}> */}
        <TextField
          required
          id="firstName"
          name="firstName"
          label="First name"
          fullWidth
          autoComplete="given-name"
          value={formState.firstName}
          onChange={handleInputChange}
        />
        {/* </Grid> */}
      </div>
      <div className="grid grid-cols-1 gap-4 my-4">
        {/* <Grid item xs={12}> */}
        <TextField
          required
          id="address"
          name="address"
          label="Address"
          fullWidth
          autoComplete="street-address"
          value={formState.address}
          onChange={handleInputChange}
        />
        {/* </Grid> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        {/* <Grid item xs={12} sm={6}> */}
        <TextField
          required
          id="zip"
          name="zip"
          label="Zip"
          fullWidth
          autoComplete="postal-code"
          value={formState.zip}
          onChange={handleInputChange}
        />
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={6}> */}
        <TextField
          required
          id="city"
          name="city"
          label="City"
          fullWidth
          autoComplete="address-level2"
          value={formState.city}
          onChange={handleInputChange}
        />
        {/* </Grid> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 mb-4 gap-4">
        {/* <Grid item xs={12} sm={6}> */}
        <TextField
          required
          id="dob"
          name="dob"
          label="Date of Birth"
          fullWidth
          type="date"
          InputLabelProps={{ shrink: true }}
          value={formState.dob}
          onChange={handleInputChange}
        />
        {/* </Grid> */}
        <div className="gap-4 w-full h-full flex items-center grid-cols-2">
          <PhoneInput
            country={phoneNumber?.countryCode}
            inputProps={{
              className:
                "h-full w-full pl-11 pt-4 pb-4 bg-transparent rounded  ",
              style: { border: "1px solid #ccc" },
              placeholder: "Mobile Number",
            }}
            name="phoneNumber"
            value={phoneNumber?.value}
            className="h-full"
            disabled={isMobileVerified}
            InputProps={{
              style: {
                backgroundColor: `${
                  isMobileVerified ? "rgb(220 252 231)" : ""
                }`,
                border: "none",
              },
            }}
            onChange={async (value, formattedValue) => {
              setPhoneNumber({
                countryCode: formattedValue.countryCode,
                value: value,
                dailCode: formattedValue.dialCode,
              });
              setFormState({
                ...formState,
                phoneNumber: value,
              });
              await checkPhoneNumberDB(value);
            }}
          />
        </div>
      </div>
      <div
        className="mb-4 grid grid-cols-1 md:grid-cols-2
       gap-4"
      >
        {/* <div className="grid grid-cols-6 gap-3"> */}
        <div className="col-span-4 xl:col-span-5 flex flex-col">
          {/* <Grid item xs={12} sm={6} className=""> */}
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            type="email"
            value={formState.email}
            onChange={handleInputChange}
            // disabled={isEmailVerified || verifingEmail}
            // InputProps={{
            //   style: {
            //     backgroundColor: `${isEmailVerified ? "rgb(220 252 231)" : ""
            //       }`,
            //     border: "none",
            //   },
            // }}
          />
          {/* </Grid> */}

          {/* <span
              className={`ml-1 ${isEmailVerified ? "text-green-500" : "text-red-500"
                } text-sm`}
            >
              {isEmailVerified ? "Email is verify !" : "Email not verify yet !"}
            </span> */}
          {/* </div> */}
          {/* <button
            type="button"
            disabled={isEmailVerified}
            onClick={() => verifyValidEmail()}
            className="text-white rounded-lg col-span-2 xl:col-span-1 bg-blue-800 h-fit px-2.5 py-4 hover:bg-blue-900 border border-gray-300 font-medium text-sm"
          > */}
          {/* {verifingEmail ? <Spin spinning={verifingEmail} /> : "Verify"} */}
          {/* {verifingEmail ? (
              <div className="w-[100px]">
                <Spin />
              </div>
            ) : (
              "Verify"
            )} */}
          {/* </button> */}
        </div>
        {/* {role !== "admin" && ( */}
        {/* <div className="col-span-1 grid grid-cols-6 gap-3">
            <div className="col-span-4 xl:col-span-5 flex flex-col"> */}
        {/* <Grid item xs={12} sm={6} className="col-span-4"> */}
        {/* <TextField
                required
                id="otp"
                name="otp"
                label="OTP"
                type="number"
                fullWidth
                value={OTP}
                onChange={(e) => setOTP(e.target.value)}
                disabled={isOtpVerified}
                InputProps={{
                  style: {
                    backgroundColor: `${isOtpVerified ? "rgb(220 252 231)" : ""
                      }`,
                    border: "none",
                  },
                }}
              /> */}
        {/* </Grid> */}
        {/* <span
                className={`ml-1 ${isOtpVerified ? " text-green-500 " : " text-red-500 "
                  } text-sm`}
              >
                {isOtpVerified ? "OTP is verified !" : "OTP not Verified yet !"}
              </span>
            </div> */}
        {/* <button
              type="button"
              disabled={isOtpVerified || verifingOTP}
              onClick={() => verifyOtp()}
              className="text-white rounded-lg col-span-2 xl:col-span-1 bg-blue-800 h-fit px-2.5 py-4 hover:bg-blue-900 border border-gray-300 font-medium text-sm"
            >
              {verifingOTP ? <Spin spinning={verifingOTP} /> : "Verify"}
            </button>
          </div> */}
        {/* )} */}
      </div>
      <div className="mb-4">
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="passportNo"
            name="passportNo"
            label="Passport No"
            fullWidth
            value={formState.passportNo.toUpperCase()}
            onChange={(e) => {
              // if (e.target.value.length === 8) {
              //  verifyValidPassport(e.target.value);
              // }
              handleInputChange(e);
            }}
            InputProps={{
              maxLength: 8,
              endAdornment: (
                <InputAdornment position="end">8 digits</InputAdornment>
              ),
            }}
          />
        </Grid>
      </div>
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* <Grid item xs={12} sm={6}> */}
        <TextField
          required
          id="state"
          name="state"
          label="State"
          fullWidth
          value={formState.state}
          onChange={handleInputChange}
        />
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={6}> */}
        <FormControl fullWidth>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-label"
            id="country"
            name="country"
            value={formState.country}
            label="Country"
            onChange={handleInputChange}
          >
            {countryCodes.map((country, index) => (
              <MenuItem key={index} value={country.name}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* </Grid> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 mb-4 gap-4">
        {/* <Grid item xs={12} sm={6}> */}
        <TextField
          required
          id="currentSchool"
          name="currentSchool"
          label="Current School"
          fullWidth
          value={formState.currentSchool}
          onChange={handleInputChange}
        />
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={6}> */}
        <TextField
          required
          id="currentDegree"
          name="currentDegree"
          label="Current Degree"
          fullWidth
          value={formState.currentDegree}
          onChange={handleInputChange}
        />
        {/* </Grid> */}
      </div>
      {/* <Grid item xs={12} sm={6}> */}
      <label htmlFor="">How did you know about Supdemod ?*:</label>
      <FormControl fullWidth>
        <Select
          value={formState.knowFrom}
          onChange={handleInputChange}
          autoFocus={true}
          className="mt-1"
          required
          name="knowFrom"
        >
          <MenuItem value="Agency">Agency</MenuItem>
          <MenuItem value="Student referral">Student referral</MenuItem>
          <MenuItem value="International business manager / Education counsellor">
            International business manager / Education counsellor
          </MenuItem>
          <MenuItem value="Our website">Our website</MenuItem>
          <MenuItem value="Social media">Social media</MenuItem>
          <MenuItem value="Others">Others</MenuItem>
        </Select>
      </FormControl>
      {/* </Grid> */}
      {formState.knowFrom === "Agency" && (
        <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* <Grid item xs={12} sm={6}> */}
          <TextField
            name="agency"
            label="Agency Name"
            id="agency"
            className="w-full"
            value={formState.agency}
            onChange={handleInputChange}
            required
          />
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={6}> */}
          <TextField
            label="Agency Email"
            type="email"
            name="agencyEmail"
            id="agencyName"
            className="w-full"
            value={formState.agencyEmail}
            onChange={handleInputChange}
            required
          />
          {/* </Grid> */}
        </div>
      )}
      {formState.knowFrom ===
        "International business manager / Education counsellor" && (
        <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <TextField
            name="staffMember"
            label="Staff Member"
            id="staffMember"
            className="w-full"
            value={formState.staffMember}
            onChange={handleInputChange}
            required
          />
        </div>
      )}
    </div>
  );
};

// Component2
const Component2 = ({ formState, handleInputChange }) => {
  return (
    <div className="p-8">
      {" "}
      <div className="grid grid-cols-1 md:grid-cols-2 mb-4">
        {/* <Grid item xs={12} sm={6}> */}
        <FormControl component="fieldset" className="flex">
          <label
            htmlFor=""
            className="font-semibold border-b p-2 border-dotted border-blue-900"
          >
            Degree Type * :
          </label>
          <RadioGroup
            aria-label="degree-type"
            name="degreeType"
            className="ml-4"
            value={formState.degreeType}
            onChange={handleInputChange}
          >
            <div className="flex md:flex-col justify-start">
              <FormControlLabel
                value="full-time"
                control={<Radio style={{ color: "#1E40AF" }} />}
                label="Full Time"
              />
              <FormControlLabel
                value="short-program"
                control={<Radio style={{ color: "#1E40AF" }} />}
                label="SHORT PROGRAM"
              />
            </div>
          </RadioGroup>
        </FormControl>
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={6}> */}
        <FormControl component="fieldset">
          <label
            htmlFor=""
            className="font-semibold border-b p-2 border-dotted border-blue-900"
          >
            Intake * :
          </label>
          <RadioGroup
            aria-label="intake"
            name="intake"
            value={formState.intake}
            className="ml-4"
            onChange={handleInputChange}
          >
            <div className="flex md:flex-col justify-start">
              {/* <FormControlLabel
                value="jun-2024"
                control={<Radio style={{ color: "#1E40AF" }} />}
                label="June 2024"
              /> */}
              <FormControlLabel
                value="sep-2024"
                control={<Radio style={{ color: "#1E40AF" }} />}
                label="September 2024"
              />
              <FormControlLabel
                value="Jan-2025"
                control={<Radio style={{ color: "#1E40AF" }} />}
                label="January 2025"
              />
              <FormControlLabel
                value="others"
                control={<Radio style={{ color: "#1E40AF" }} />}
                label="Others"
              />
            </div>
          </RadioGroup>
        </FormControl>
        {/* </Grid> */}
      </div>
      <div className="mb-4">
        {/* <Grid item xs={12} sm={6} className="relative"> */}
        <FormControl fullWidth>
          <InputLabel id="diploma-label">Degree</InputLabel>
          <Select
            labelId="diploma-label"
            id="diploma"
            name="diploma"
            value={formState.diploma}
            label="Degree"
            onChange={handleInputChange}
          >
            <MenuItem value="BBA">BACHELOR INTERNATIONAL</MenuItem>
            <MenuItem value="MBA">
              MASTER OF BUSINESS ADMINISTRATION INTERNATIONAL
            </MenuItem>
            <MenuItem value="BTS">BTS</MenuItem>
          </Select>
        </FormControl>
        {/* </Grid> */}
      </div>
      <div>
        {formState.diploma === "BBA" && (
          <BBAComponent
            formState={formState}
            handleInputChange={handleInputChange}
          />
        )}
        {formState.diploma === "MBA" && (
          <MBAComponent
            formState={formState}
            handleInputChange={handleInputChange}
          />
        )}
        {formState.diploma === "BTS" && (
          <BTSComponent
            formState={formState}
            handleInputChange={handleInputChange}
          />
        )}
      </div>
    </div>
  );
};

// Component3
const Component3 = ({ formState, handleInputChange }) => {
  return (
    <div className="p-2 md:p-8">
      <div className="overflow-hidden grid grid-cols-1 my-8 gap-4 xl:grid-cols-2">
        {[
          { label: "Passport Copy *", name: "passportCopy" },
          { label: "Highest Degree Certificate *", name: "relevantDiploma" },
          { label: "Resume / CV *", name: "resume" },
          { label: "Medium of Instruction (MOI)", name: "coverLetter" },
          { label: "All Marksheet *", name: "marksheet" },
          { label: "Passport Photo", name: "idPhoto" },
        ].map((field) => (
          <div className="flex flex-col mx-auto" key={field.name}>
            <label className="mb-2 font-semibold">{field.label} :</label>
            <div className="file-input border flex flex-row">
              <input
                type="file"
                accept=".pdf, .jpg, .jpeg, .png"
                name={field.name}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className={`px-4 py-2 mx-2 rounded-sm ${
                  formState[field.name + "Name"]
                    ? "bg-blue-900 text-white"
                    : "bg-gray-300 text-black"
                }`}
              >
                {formState[field.name + "Name"]
                  ? "File Selected"
                  : "Choose File"}
              </button>
              <span className="label" data-js-label>
                {formState[field.name + "Name"] || "No file selected"}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Form = () => {
  const getRole = () => {
    if (localStorage.getItem("role"))
      return decryptRole(localStorage.getItem("role"));
    else return null;
  };
  const role = getRole();
  /*Time Out Feature*/
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [OTP, setOTP] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [verifingEmail, setVerifingEmail] = useState(false);
  const [verifingOTP, setVerifingOTP] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: "fr",
    value: "",
    dailCode: "33",
  });
  const [isPassportValid, setIsPassportValid] = useState(false); //
  const [currentStep, setCurrentStep] = useState(1);
  const [lastActivityTime, setLastActivityTime] = useState(
    new Date().getTime()
  );

  const resetFormAndRedirect = () => {
    resetForm();
  };
  const handleUserActivity = () => {
    setLastActivityTime(new Date().getTime());
  };
  useEffect(() => {
    const sessionTimeout = 10 * 60 * 1000; // 10 minutes in milliseconds

    const timeoutId = setTimeout(() => {
      const currentTime = new Date().getTime();
      if (currentTime - lastActivityTime >= sessionTimeout) {
        resetFormAndRedirect();
      }
    }, sessionTimeout);
    return () => clearTimeout(timeoutId);
  }, [lastActivityTime]);
  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await fetch("https://api.first.org/data/v1/countries");
        const data = await response.json();

        const codes = Object.values(data.data).map((country) => ({
          code: country.alpha2,
          name: country.country,
        }));

        setCountryCodes(codes);
      } catch (error) {
        console.error("Error fetching country codes:", error);
      }
    };

    fetchCountryCodes();
    window.scrollTo(0, 0);
    setIsOtpVerified(role === "admin");
  }, []);

  const validatePanels = async () => {
    let emptyFields;
    let isPhoneNumberValid = false;
    if (currentStep === 1) {
      if (!(await checkPhoneNumberDB(phoneNumber.value))) {
        return;
      }
      // if (!isPassportValid && formState.passportNo.length === 8) {
      if (!(await verifyValidPassport(formState.passportNo))) {
        return;
      }
      emptyFields = firstPanelValidation(formState);
      isPhoneNumberValid = validatePhoneNumber(
        phoneNumber.value.length - phoneNumber.dailCode.length,
        phoneNumber.countryCode.toUpperCase()
      );
    } else {
      emptyFields = secondPanelValidation(formState);
    }
    if (emptyFields.length > 0) {
      const firstEmptyFieldName = emptyFields[0];
      if (firstEmptyFieldName === "passportNo") {
        toast.error(
          `*${firstEmptyFieldName.toUpperCase()}* it is remaining to fill !`
        );
        // return;
      } else if (firstEmptyFieldName === "diploma") {
        toast.error("DEGREE is required and it is remainaing to fill !");
      } else {
        toast.error(
          `*${firstEmptyFieldName.toUpperCase()}* is required and it is remaining to fill !`
        );
        // return;
      }
      const firstEmptyField = document.getElementsByName(firstEmptyFieldName);
      if (
        firstEmptyFieldName === "degreeType" ||
        firstEmptyFieldName === "phoneNumber"
      ) {
      } else {
        firstEmptyField[0].focus();
      }

      return;
    } else if (currentStep === 1 && !isPhoneNumberValid) {
      toast.error(`Phone number is invalid !`);
      return;
    } else if (currentStep === 1 && (!isEmailVerified || !isOtpVerified)) {
      toast.error("Please Verify you Email !");
      return;
    } else {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
    }
  };

  const checkPhoneNumberDB = async (value) => {
    const selectedCountry = AllCountryPhoneNumberDetails.find(
      (country) => country.code == phoneNumber.countryCode.toUpperCase()
    );
    if (
      selectedCountry &&
      value.length ===
        selectedCountry.phoneLength + phoneNumber.countryCode.length
    ) {
      const data = await checkPhoneNumberInDb({
        phoneNumber: value,
      });
      if (!data.success) {
        toast.error(data.error);
        return false;
      }
    }
    return true;
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const [formState, setFormState] = useState({
    title: "Mr.",
    firstName: "",
    lastName: "",
    address: "",
    zip: "",
    city: "",
    passportNo: "",
    country: "",
    state: "",
    dob: "",
    phoneNumber: "",
    email: "",
    currentSchool: "",
    currentDegree: "",
    knowFrom: "",
    agency: "",
    agencyEmail: "",
    staffMember: "",
    // degreeType: "",
    // intake: "",
    // diploma: "",
    // bba: "",
    // bbaDegree: "",
    // mba: "",
    // mbaDegree: "",
    // bts: "",
    passportCopy: null,
    relevantDiploma: null,
    resume: null,
    coverLetter: null,
    marksheet: null,
    idPhoto: null,
    passportCopyName: "",
    relevantDiplomaName: "",
    resumeName: "",
    coverLetterName: "",
    marksheetName: "",
    idPhotoName: "",
    college:"Supdemod"
  });

  // const verifyValidEmail = async () => {
  //   setVerifingEmail(true);
  //   if (!EmailValidator.validate(formState["email"])) {
  //     toast.error("Invalid Email ID !");
  //     setVerifingEmail(false);
  //     return;
  //   }
  //   const data = await sendVerification({
  //     email: formState.email,
  //     role: role,
  //   });
  //   if (data.success) {
  //     setIsEmailVerified(true);
  //     toast.success(data.msg);
  //   } else {
  //     toast.error(data.error);
  //   }
  //   setVerifingEmail(false);
  // };

  const verifyValidPassport = async (passportNo) => {
    if (passportNo.length >= 8 && passportNo.length <= 10) {
      const data = await sendPassportVerification({
        passportNo,
      });
      if (!data.success) {
        setIsPassportValid(false);
        toast.error(data.error);
        return false;
      } else {
        setIsPassportValid(true);
        return true;
      }
    } else {
      toast.error("Passport number length should be between 8 to 10 only!");
      setIsPassportValid(false);
      return false;
    }
  };

  // const verifyOtp = async () => {
  //   setVerifingOTP(true);

  //   try {
  //     // Check if the email is verified by calling verifyEmail function
  //     const emailVerificationResult = await verifyEmail({
  //       email: formState.email,
  //       code: OTP,
  //     });

  //     if (emailVerificationResult && emailVerificationResult.success) {
  //       // Email is verified, proceed with OTP verification
  //       const data = await verifyEmail({
  //         email: formState.email,
  //         code: OTP,
  //       });

  //       if (data && data.success) {
  //         setIsOtpVerified(true);
  //         toast.success(data.msg);
  //       } else {
  //         toast.error(
  //           data ? data.error : "An error occurred during OTP verification."
  //         );
  //       }
  //     } else {
  //       // Email is not verified
  //       toast.error(emailVerificationResult.error);
  //     }
  //   } catch (error) {
  //     toast.error("An error occurred during email verification.");
  //   } finally {
  //     setVerifingOTP(false);
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, type, value, files } = e.target;
    const currentDate = new Date().toISOString().split("T")[0];

    if (name === "dob" && value > currentDate) {
      toast.error("Date of Birth cannot be in the future.");
      setFormState((prevData) => ({
        ...prevData,
        dob: "", // Reset the value to an empty string
      }));
      return;
    }

    if (type === "file") {
      const file = files[0];
      const allowedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];

      if (file) {
        if (allowedFileTypes.includes(file.type)) {
          setFormState((prevState) => ({
            ...prevState,
            [name]: file,
            [name + "Name"]: file.name,
          }));
        } else {
          toast.error("Please select a valid file type (PDF, JPG, JPEG, PNG).");
          e.target.value = null; // Clear the invalid file from the input
        }
      }
    } else if (name === "zip") {
      if (value !== "" && !/^[0-9]+$/.test(value)) {
        toast.error("Please enter only numbers for ZIP.");
        return;
      }
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (name === "passportNo") {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value ? value.toUpperCase() : "",
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const createFormDataObject = (initialState) => {
    const fdx = new FormData();
    for (const [key, value] of Object.entries(initialState)) {
      fdx.append(key, value);
    }
    return fdx;
  };
  // const updateCurrentStep = (firstEmptyFieldName) => {
  //   const studentInformationFields = [
  //     "title",
  //     "firstName",
  //     "lastName",
  //     "address",
  //     "zip",
  //     "dob",
  //     "city",
  //     "email",
  //     "country",
  //     "state",
  //     "passportNo",
  //     "currentSchool",
  //     "currentDegree",
  //     "knowFrom",
  //     "agency",
  //     "agencyEmail",
  //     "staffMember",
  //   ];
  //   const AdmissionInformationFields = [
  //     "degreeType",
  //     "intake",
  //     "diploma",
  //     "bba",
  //     "bbaDegree",
  //     "mba",
  //     "mbaDegree",
  //     "bts",
  //   ];
  //   const documentUploadFields = [
  //     "passportCopy",
  //     "relevantDiploma",
  //     "resume",
  //     "coverLetter",
  //     "marksheet",
  //     "idPhoto",
  //   ];
  //   return new Promise((resolve, reject) => {
  //     if (studentInformationFields.includes(firstEmptyFieldName)) {
  //       setCurrentStep(1);
  //     } else if (AdmissionInformationFields.includes(firstEmptyFieldName)) {
  //       setCurrentStep(2);
  //     } else {
  //       setCurrentStep(3);
  //     }
  //     resolve();
  //   });
  // };

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  function validateFormState(formState) {
    const emptyFields = [];

    for (const [key, value] of Object.entries(formState)) {
      if (
        key === "staffMember" &&
        formState["knowFrom"] ===
          "International business manager / Education counsellor"
      ) {
        if (formState[key] === "") emptyFields.push(key);
        // International business manager / Education counsellor
      } else if (value === "" || value === null) {
        if (key === "agency" || key === "agencyEmail") {
          if (formState["knowFrom"] === "Agency") emptyFields.push(key);
          // International business manager / Education counsellor
        } else if (key === "staffMember") {
          if (
            formState["knowFrom"] ===
            "International business manager / Education counsellor"
          )
            emptyFields.push(key);
        } else {
          emptyFields.push(key);
        }
      }
    }

    if (emptyFields.length > 0) {
      toast.error(`Please Fill up ${emptyFields[0]}`);
      if (
        ![
          "passportCopy",
          "relevantDiploma",
          "resume",
          "coverLetter",
          "marksheet",
          "idPhoto",
        ].includes(emptyFields[0])
      ) {
        setCurrentStep(1);
      } else {
        setCurrentStep(2);
      }
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setLoading1(true);
      if (!validateFormState(formState)) {
        setLoading(false);
        setLoading1(false);
        return;
      }
      // const maxFileSizeInMB = 5;
      // const mandatoryFileFields = [
      //   { name: "passportCopy", label: "Passport Copy" },
      //   { name: "relevantDiploma", label: "Highest Degree Certificate" },
      //   { name: "resume", label: "Resume" },
      //   { name: "marksheet", label: "All Marksheet" },
      // ];

      // const optionalFileFields = [
      //   { name: "coverLetter", label: "Medium of Instruction (MOI)" },
      //   { name: "idPhoto", label: "Passport Photo" },
      // ];

      // for (const field of mandatoryFileFields) {
      //   const file = formState[field.name];
      //   if (!file) {
      //     toast.error(
      //       `${field.label} file not found. Please re-upload the file.`
      //     );
      //     return;
      //   }
      //   if (file.size > maxFileSizeInMB * 1024 * 1024) {
      //     toast.error(
      //       `${field.label} must be less than ${maxFileSizeInMB}MB in size`
      //     );
      //     return;
      //   }
      // }

      // for (const field of optionalFileFields) {
      //   const file = formState[field.name];
      //   if (file && file.size > maxFileSizeInMB * 1024 * 1024) {
      //     toast.error(
      //       `${field.label} must be less than ${maxFileSizeInMB}MB in size`
      //     );
      //     return;
      //   }
      // }

      // const emptyFields = validateForm(formState, phoneNumber);
      // if (emptyFields.length > 0) {
      //   const firstEmptyFieldName = emptyFields[0];
      //   if (firstEmptyFieldName === formState.passportNo) {
      //     toast.error(
      //       `*${firstEmptyFieldName.toUpperCase()}* is Maximum 7 digit required and it is remaining to fill !`
      //     );
      //   } else {
      //     toast.error(
      //       `${firstEmptyFieldName.toUpperCase() === "RELEVANTDIPLOMA"
      //         ? "*HIGHEST DEGREE CERTIFICATE*"
      //         : "*" + firstEmptyFieldName.toUpperCase() + "*"
      //       } is required and it is remaining to fill!`
      //     );
      //   }
      //   updateCurrentStep(firstEmptyFieldName).then(() => {
      //     const firstEmptyField =
      //       document.getElementsByName(firstEmptyFieldName);
      //     if (firstEmptyField.length > 0) {
      //       firstEmptyField[0].focus();
      //     }
      //   });
      //   return;
      // }

      // const fieldsWithSameName = validateFiles(formState);
      // const isValidPhoneNumber = validatePhoneNumber(
      //   phoneNumber.value.length - phoneNumber.dailCode.length,
      //   phoneNumber.countryCode.toUpperCase()
      // );

      // if (!isValidPhoneNumber) {
      //   setCurrentStep(1);
      //   toast.error("Invalid Phone Number. Please enter a valid phone number.");
      //   return;
      // }

      // for (const [fileName, fieldNames] of Object.entries(fieldsWithSameName)) {
      //   if (fieldNames.length > 1) {
      //     toast.error(`${fieldNames.join(", ")} file have the same name!`);
      //     return;
      //   }
      // }

      // if (!isEmailVerified || !isOtpVerified) {
      //   toast.error("Your Email or OTP is not verified yet!");
      //   return;
      // }

      let formDataObject = createFormDataObject(formState);
      try {
        const data = await studentRegister(formDataObject);
        if (data.success) {
          // FormSubmissionEmail(formState);
          // FormSubmissionEmailAdmin(formState);
          // FormSubmissionEmailBDM(formState);
          toast.success("Form Submitted successfully !");
          resetForm();
        } else if (data.error) {
          toast.error(data.error);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error submitting form");
      }
    } catch (error) {
      toast.error("Error submitting form");
    } finally {
      setLoading(false);
      setLoading1(false);
    }
  };

  const getStepName = (step) => {
    // Replace with your actual step names
    switch (step) {
      case 1:
        return "Personal information";
      case 3:
        return "Education Details";
      case 2:
        return "Upload documents";
      default:
        return "";
    }
  };

  const resetForm = () => {
    // Reset the form state here
    setFormState({
      title: "",
      firstName: "",
      lastName: "",
      address: "",
      zip: "",
      city: "",
      passportNo: "",
      country: "",
      dob: "",
      phoneNumber: "",
      email: "",
      currentSchool: "",
      currentDegree: "",
      agency: "",
      agencyEmail: "",
      // degreeType: "",
      // intake: "",
      // diploma: "",
      // bba: "",
      // bbaDegree: "",
      // mba: "",
      // mbaDegree: "",
      staffMember: "",
      // bts: "",
      knowFrom: "",
      passportCopy: null,
      relevantDiploma: null,
      resume: null,
      coverLetter: null,
      marksheet: null,
      idPhoto: null,
    });
    setPhoneNumber({
      countryCode: "in",
      value: "",
      dailCode: "91",
    });
    setOTP("");
    setIsEmailVerified(false);
    setIsMobileVerified(false);
    setIsOtpVerified(false);
    setCurrentStep(1);
  };

  return (
    // <div className="w-full mt-24 relative top-0 left-0 pt-10 bg-white">
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <form className="w-11/12 min-h-screen mt-5 mb-10 mx-auto md:10/12 lg:w-8/12">
        <div className="mx-auto bg-white rounded-xl drop-shadow-2xl overflow-hidden block md:relative ">
          <div className="p-8 mx-auto flex justify-between items-center space-x-4">
            {Array.from({ length: 2 }, (_, index) => (
              <div key={index} className="flex items-center gap-4">
                <div
                  className={`w-8 h-6 md:h-8 rounded-full flex transition-transform ease-in-out duration-300 items-center justify-center ${
                    currentStep === index + 1
                      ? "bg-blue-900 text-white"
                      : "bg-gray-300 text-gray-600"
                  }`}
                >
                  {index + 1}
                </div>
                <p
                  className={`text-sm font-medium transition-transform ease-in-out duration-1000 ${
                    currentStep === index + 1
                      ? "text-blue-900"
                      : "text-gray-600"
                  }`}
                >
                  {getStepName(index + 1)}
                </p>
              </div>
            ))}
          </div>

          <div className="overflow-hidden">
            {Array.from({ length: 3 }, (_, index) => (
              <div
                key={index}
                className={` w-full transform transition-transform ease-in-out duration-800 ${
                  currentStep === index + 1
                    ? "translate-x-0"
                    : "absolute hidden -translate-x-full"
                }`}
              >
                {currentStep === 1 && (
                  <Component1
                    formState={formState}
                    handleInputChange={handleInputChange}
                    setFormState={setFormState}
                    countryCodes={countryCodes}
                    setPhoneNumber={setPhoneNumber}
                    phoneNumber={phoneNumber}
                    isEmailVerified={isEmailVerified}
                    isMobileVerified={isMobileVerified}
                    isOtpVerified={isOtpVerified}
                    verifingEmail={verifingEmail}
                    verifingOTP={verifingOTP}
                    // verifyValidEmail={verifyValidEmail}
                    verifyValidPassport={verifyValidPassport}
                    // verifyOtp={verifyOtp}
                    checkPhoneNumberDB={checkPhoneNumberDB}
                    OTP={OTP}
                    setOTP={setOTP}
                    role={role}
                  />
                )}
                {currentStep === 2 && (
                  <Component3
                    formState={formState}
                    handleInputChange={handleInputChange}
                    setFormState={setFormState}
                    countryCodes={countryCodes}
                    setPhoneNumber={setPhoneNumber}
                    phoneNumber={phoneNumber}
                  />
                )}
              </div>
            ))}
          </div>

          <div className="flex justify-between p-8">
            <button
              type="button"
              id="previous"
              onClick={() => {
                handlePrevious();
                window.scrollTo(0, 0);
              }}
              className={`px-4 py-2 text-blue-900 text-4xl bg-tarnsparent font-extrabold rounded ${
                currentStep === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={currentStep === 1}
            >
              <HiArrowCircleLeft />
            </button>
            <button
              type="button"
              id="next"
              onClick={() => {
                setCurrentStep((prevStep) => Math.min(prevStep + 1, 2));
                window.scrollTo(0, 0);
              }}
              className={`px-4 py-2 text-4xl font-extrabold text-blue-900 bg-tarnsparent rounded ${
                currentStep === 2 ? "opacity-50 cursor-not-allowed hidden" : ""
              }`}
              disabled={currentStep === 2}
            >
              <HiArrowCircleRight />
            </button>
            <button
              type="button"
              id="submit"
              className={`px-4 z-0  text-blue-900  border-2 border-blue-900 rounded  hover:text-white hover:bg-blue-900 transition duration-300 ${
                currentStep === 2 ? "block" : "hidden"
              } `}
              onClick={handleSubmit}
            >
              {loading1 ? (
                <div className="flex justify-center  items-center">
                  <Spin size="large" spinning={loading1} />
                </div>
              ) : (
                "Apply Now"
              )}
            </button>
          </div>
        </div>
      </form>
      {/* </div> */}
    </>
  );
};

export default Form;
